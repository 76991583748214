/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, flow, makeObservable, observable } from "mobx";

import type { Filter } from "@config/filters.const";
import { logger as baseLogger } from "@core/logger";
import { sleep } from "@core/utils";

import type { RootStoreType } from "./root_store";

const logger = baseLogger.getSubLogger({ name: "filterStore" });

export const LOCAL_STORAGE_FILTERS_KEY = "filters";
export const LOCAL_STORAGE_PREFERENCES_KEY = "optAndScenarioPreferences";
const LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY = "globalPreferences";

class UserPreferences {
  parent: RootStoreType;
  _enabled = true;
  _update_pending = false;
  fav_subs = new Map();
  networkId?: string;
  userId?: string;
  internalFeaturesEnabled = false;

  constructor(parent: RootStoreType) {
    makeObservable(this, {
      parent: true,
      _enabled: true,
      _update_pending: true,
      getFilterStorage: true,
      clearFilterStorage: true,
      upsertFilterStorage: true,
      filtersInLocalStorage: computed,
      hasLocalFiltersCache: computed,
      networkId: observable,
      userId: observable,
      upsertSimResultCollapsible: true,
      disableSync: true,
      toggleFavSub: true,
      clearFavorites: true,
      toJson: true,
      fav_subs: observable,
      resetPref: action.bound,
      fromJson: action.bound,
      getSimulationResultInLocalStorage: true,
      getSimResultCollapsibleTab: true,
      setUserId: action.bound,
      updateInternalFeaturesEnabled: action.bound,
      internalFeaturesEnabled: observable,
    });
    this.parent = parent;
    this.disableSync = this.disableSync.bind(this);

    // Read from localStorage
    this.internalFeaturesEnabled = this.isInternalFeaturesEnabled;
  }

  get filtersInLocalStorage(): { [networkId: string]: { [userId: string]: any } } | null {
    const storedFilters = localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY);
    return storedFilters !== null ? JSON.parse(storedFilters) : null;
  }

  get hasLocalFiltersCache(): boolean {
    return (
      typeof this.networkId === "string" &&
      typeof this.userId === "string" &&
      Boolean(this.filtersInLocalStorage?.[this.networkId]?.[this.userId])
    );
  }

  setUserId(userId: string) {
    this.userId = userId;
    this.networkId = this.parent.networks.current_network?.uid;
  }

  disableSync(): void {
    this._enabled = false;
  }

  toggleFavSub = flow(function* (
    this: UserPreferences,
    network_id: string,
    sub_id: string
  ): Generator<any, void, unknown> {
    let cnet = null;
    if (this.fav_subs.has(network_id)) {
      cnet = this.fav_subs.get(network_id);
    } else {
      cnet = new Set();
      this.fav_subs.set(network_id, cnet);
    }
    if (cnet.has(sub_id)) {
      cnet.delete(sub_id);
    } else {
      cnet.add(sub_id);
    }

    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
    yield this.parent.filters.updateFavouritesFilter();
  });

  resetPref() {
    this.fav_subs.clear();
  }

  clearFavorites = flow(function* (this: UserPreferences, network_id: string) {
    this.fav_subs.set(network_id, new Set<string>());
    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
  });

  fromJson(jsn: { fav_subs: { [networkId: string]: unknown[] } }) {
    if (Object.hasOwn(jsn, "fav_subs")) {
      const updatedFavSubs = new Map<string, Set<string>>(
        Object.entries(jsn.fav_subs).map(([networkId, subs]) => [
          networkId,
          new Set<string>(subs as string[]),
        ])
      );
      this.fav_subs = updatedFavSubs;
    }
  }

  toJson() {
    const resjson: { fav_subs: { [networkId: string]: string[] } } = { fav_subs: {} };
    for (const [networkId, favSet] of this.fav_subs.entries()) {
      resjson.fav_subs[networkId] = Array.from(favSet);
    }
    return resjson;
  }

  get isInternalFeaturesEnabled(): boolean {
    // Read from localStorage
    const item = localStorage.getItem(LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY);
    const globalPreferences: {
      internalFeaturesEnabled?: boolean;
    } = item ? JSON.parse(item) : {};

    return globalPreferences.internalFeaturesEnabled ?? false;
  }

  updateInternalFeaturesEnabled(enabled: boolean) {
    // Write to localStorage
    const globalPreferences = {
      internalFeaturesEnabled: enabled,
    };
    localStorage.setItem(LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY, JSON.stringify(globalPreferences));
    this.internalFeaturesEnabled = enabled;
  }

  upsertSimResultCollapsible(headingTitle: string | number): void {
    if (!this.networkId || !this.userId) return;
    logger.debug(`Upserting ${headingTitle} in simResultCollapsible in localStorage`);
    const simResultCollapsible = this.getSimulationResultInLocalStorage() || {
      simResultCollapse: {},
    };
    const userCollapsible =
      simResultCollapsible.simResultCollapse?.[this.networkId]?.[this.userId] || {};

    const collapsibleByTitle =
      userCollapsible[headingTitle] !== undefined ? !userCollapsible[headingTitle] : true; // Default to true if undefined

    userCollapsible[headingTitle] = collapsibleByTitle;
    simResultCollapsible.simResultCollapse = {
      ...simResultCollapsible.simResultCollapse,
      [this.networkId]: {
        ...simResultCollapsible.simResultCollapse?.[this.networkId],
        [this.userId]: userCollapsible,
      },
    };

    localStorage.setItem(LOCAL_STORAGE_PREFERENCES_KEY, JSON.stringify(simResultCollapsible));
  }

  getSimulationResultInLocalStorage(): {
    simResultCollapse?: Record<string, Record<string, Record<string | number, boolean>>>;
  } | null {
    const item = localStorage.getItem(LOCAL_STORAGE_PREFERENCES_KEY);
    return item ? JSON.parse(item) : null;
  }

  getSimResultCollapsibleTab(headingTitle: string | number): boolean {
    if (!this.networkId || !this.userId) return true;
    const { simResultCollapse } = this.getSimulationResultInLocalStorage() || {};
    return (
      simResultCollapse?.[this.networkId]?.[this.userId]?.[headingTitle] ??
      // Default to true
      true
    );
  }

  getFilterStorage(): Array<{ data: Map<unknown, unknown> } | undefined> | undefined {
    if (!this.networkId || !this.userId || !this.filtersInLocalStorage) return;
    return this.filtersInLocalStorage[this.networkId]?.[this.userId].map(
      (filter: { data?: [unknown, unknown][] | undefined }) => ({
        ...filter,
        data: new Map(filter.data || []),
      })
    );
  }
  clearFilterStorage() {
    localStorage.removeItem(LOCAL_STORAGE_FILTERS_KEY);
  }

  /**
   * Upserts filters in local storage.
   *
   * @param {Filter[]} [filters=[]] - The filters to upsert.
   */
  upsertFilterStorage(filters: Filter[] = []) {
    if (!this.userId || !this.networkId || !Array.isArray(filters)) return;
    logger.debug("Upserting filters in localStorage...");

    // Get cached filters from local storage
    const cachedFilters = this.filtersInLocalStorage?.[this.networkId]?.[this.userId] as Filter[];

    // Map over filters and merge with cached filters
    const newCachedFilters = filters.map((filter) => {
      const cachedFilter = cachedFilters?.find((cf) => cf.param === filter.param);
      const selected = cachedFilter?.state?.selected || filter.state?.selected;
      const min = cachedFilter?.state?.min || filter.state?.min;
      const max = cachedFilter?.state?.max || filter.state?.max;

      // Only update selected state if it exists
      if (selected?.size) {
        return { ...filter, state: { ...filter.state, selected, min, max } };
      }

      return filter;
    });

    // Update filters in local storage
    const newFiltersInLocalStorage = {
      ...this.filtersInLocalStorage,
      [this.networkId]: {
        ...(this.filtersInLocalStorage?.[this.networkId] || {}),
        [this.userId]: newCachedFilters,
      },
    };

    localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(newFiltersInLocalStorage));
  }
}

export default UserPreferences;
