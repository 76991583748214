/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import grey from "@mui/material/colors/grey";
import type { Palette, PaletteColorOptions, ThemeOptions } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import type { Typography } from "@mui/material/styles/createTypography";
import type { OverridesStyleRules } from "@mui/material/styles/overrides";

// Base (Custom Colors & Fonts) -----------------------------------------------
// - Primary Brand Color (Blue)
const utfPrimary = {
  main: "#315085",
  dark: "#22375C",
  light: "#657CA4",
} as PaletteColorOptions;
// - Alternate Brand Color (Orange)
const utfSecondary = {
  main: "#DB742B",
  dark: "#A45720",
  light: "#E49760",
} as PaletteColorOptions;
// - Fonts
const mainFontFamily = "Roboto";
const altFontFamily = "Montserrat";
const condensedFontFamily = "Roboto Condensed";
// - Shadows
const shadows = [
  "none",
  ...Array.from({ length: 24 })
    .fill("")
    .map((_, level) => `0 ${1 + level}px ${3 + level}px 0 rgba(0, 0, 0, ${0.16 + 0.01 * level})`),
] as ThemeOptions["shadows"];

let baseTheme = createTheme({
  palette: {
    mode: "light",
    tonalOffset: 0.2,
    // To meet the minimum contrast of at least 4.5:1 as defined in WCAG 2.1 Rule 1.4.3
    contrastThreshold: 4.5,
    background: {
      default: "#f5f5f5",
      paper: "#fff",
      // custom
      selected: "#ebebeb",
      hover: "#f5f5f5",
    },
    // @deprecated. Use `palette.{color}.main|light|dark` instead
    graph: {
      blue: "#4A88BB",
      lightblue: "#a4c3dd",
      lightPink: "#dda4d4",
      pink: "#BB4AAA",
      darkPink: "#bc5090",
      lightTeal: "#a4dbdd",
      teal: "#4AB7BB",
      yellow: "#BBAE4A",
      darkYellow: "#d9bb3c",
      purple: "#6D4ABB",
      lightGreen: "#a8dda4",
      green: "#53bb4a",
      red: "#BB4A4A",
      lightOrange: "#ecb893",
      orange: "#db742b",
    },
    text: {
      primary: grey[800],
    },
  },
  typography: {
    fontFamily: mainFontFamily,
    h1: {
      fontFamily: mainFontFamily,
      fontSize: "5.714rem",
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h2: {
      fontFamily: mainFontFamily,
      fontSize: "2.857rem",
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h3: {
      fontFamily: mainFontFamily,
      fontSize: "1.286rem",
      lineHeight: 1.33,
      fontWeight: 500,
    },
    h4: {
      fontFamily: altFontFamily,
      fontWeight: "bold",
      lineHeight: 1.71,
      fontSize: "1rem",
    },
    h5: {
      fontFamily: altFontFamily,
      fontWeight: 600,
      lineHeight: 1.71,
      fontSize: "1rem",
    },
    h6: {
      fontFamily: altFontFamily,
      fontWeight: 500,
      lineHeight: 1.14,
      fontSize: "1rem",
    },
    body1: {
      fontFamily: mainFontFamily,
      fontSize: "1rem",
      lineHeight: 1.14,
      fontWeight: 300,
    },
    body2: {
      fontFamily: altFontFamily,
      fontSize: "1rem",
      lineHeight: 1.14,
      fontWeight: 500,
    },
    button: {
      fontFamily: condensedFontFamily,
      fontSize: ".929rem",
      fontWeight: "bold",
      fontStretch: "condensed",
      lineHeight: 1.23,
    },
    subtitle1: {
      fontFamily: altFontFamily,
      fontSize: "1.714rem",
      fontWeight: 600,
      lineHeight: 1.33,
    },
    subtitle2: {
      fontFamily: altFontFamily,
      fontWeight: 600,
      fontSize: "1.143rem",
      lineHeight: 1.5,
    },
    caption: {
      fontFamily: altFontFamily,
      fontSize: "0.857rem",
      fontWeight: 500,
      lineHeight: 1.33,
    },
  },
  shape: {
    borderRadius: 2,
  },
  shadows,
});

// Palette & Typography -------------------------------------------------------
baseTheme = createTheme(baseTheme, {
  palette: {
    primary: baseTheme.palette.augmentColor({ color: utfPrimary, name: "primary" }),
    secondary: baseTheme.palette.augmentColor({ color: utfSecondary, name: "secondary" }),
    error: { main: "#bb4a4a" },
    success: {
      main: "#53B84A",
    },
    warning: {
      main: "#FFC107",
    },
    info: {
      main: "#4AB7BB",
    },
    action: {
      // Override "disabled" colors with system palette
      disabled: baseTheme.palette.primary.contrastText,
      disabledBackground: baseTheme.palette.grey["400"],
    },
    border: {
      primary: "#dbe0e4",
      light: "#eee",
      divider: baseTheme.palette.augmentColor({
        color: {
          main: "#2A4471",
          dark: "#1D2F4E",
        },
        name: "divider",
      }),
    },

    // Graph Colors
    red: baseTheme.palette.augmentColor({
      color: {
        main: "#C44D4B",
        dark: "#933A38",
        light: "#D37A78",
      },
      name: "red",
    }),
    green: baseTheme.palette.augmentColor({
      color: {
        main: "#5BCF65",
        dark: "#449B4C",
        light: "#84DB8C",
      },
      name: "green",
    }),
    blue: baseTheme.palette.augmentColor({
      color: {
        main: "#5F84C4",
        dark: "#476393",
        light: "#87A3D3",
      },
      name: "blue",
    }),
    teal: baseTheme.palette.augmentColor({
      color: {
        main: "#5AC4CE",
        dark: "#44939B",
        light: "#84D3DB",
      },
      name: "teal",
    }),
    yellow: baseTheme.palette.augmentColor({
      color: {
        main: "#D9C94C",
        dark: "#A39739",
        light: "#E3D779",
      },
      name: "yellow",
    }),
    purple: baseTheme.palette.augmentColor({
      color: {
        main: "#784CD4",
        dark: "#5A399F",
        light: "#9A79DF",
      },
      name: "purple",
    }),
    pink: baseTheme.palette.augmentColor({
      color: {
        main: "#D44C9A",
        dark: "#9F3974",
        light: "#DF79B4",
      },
      name: "pink",
    }),
    orange: baseTheme.palette.augmentColor({ color: utfSecondary, name: "orange" }),
  },
});

// Overrides ------------------------------------------------------------------
baseTheme = createTheme(baseTheme, {
  components: {
    // Application-wide reset & custom styles
    MuiCssBaseline: {
      styleOverrides: {
        // Push Highcharts tooltip over MUI Drawer
        ".highcharts-tooltip-container": { zIndex: "1201 !important" },
        body: {
          // Global overrides for the Scrollbar
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "0.6em",
            height: "0.6em",
            borderRadius: 5,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            borderRadius: 5,
            backgroundColor: baseTheme.palette.grey["50"],
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.2);",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 5,
            backgroundColor: baseTheme.palette.primary.main,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: baseTheme.palette.primary.light,
          },
          // Global overrides for HighCharts options button
          "& .highcharts-menu-item": {
            ...baseTheme.typography.h6,
          },
          "& .highcharts-data-table": {
            background: baseTheme.palette.grey["50"],
            "& > table": {
              color: baseTheme.palette.grey["800"],
              "& > caption": {
                ...baseTheme.typography.body2,
              },
              "& > thead": {
                ...baseTheme.typography.h5,
              },
              "& > tbody": {
                "& > tr": {
                  ...baseTheme.typography.h6,
                },
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.variant === "outlined" && {
            "&.Mui-disabled": {
              color: baseTheme.palette.grey["400"],
            },
          }),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey["800"],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey["800"],
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: baseTheme.palette.grey["800"],
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          body: {
            color: baseTheme.palette.grey["800"],
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey["800"],
        },
      },
    },
    MuiTooltip: {
      // Global overrides for the Tooltip components
      styleOverrides: {
        tooltip: {
          maxWidth: 190,
          padding: baseTheme.spacing(1, 2),
          fontFamily: baseTheme.typography.body2.fontFamily,
          fontSize: 12,
          lineHeight: 1.3,
          fontWeight: baseTheme.typography.fontWeightMedium,
          color: baseTheme.palette.grey["50"],
          backgroundColor: baseTheme.palette.grey["600"],
        },
        /* Styles applied to the tooltip (label wrapper) element if `placement` contains "right". */
        tooltipPlacementRight: {
          transformOrigin: "left center",
          margin: "0 !important",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: baseTheme.palette.secondary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: baseTheme.palette.background.selected,
            "&:hover": {
              background: baseTheme.palette.background.selected,
            },
          },
          "&:hover": {
            background: baseTheme.palette.background.hover,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.714rem",
          fontWeight: 600,
          lineHeight: 1.33,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "& .MuiAlert-message": {
            paddingBlock: 12,
          },
        },
      },
    },
  },
});

export const defaultTheme = baseTheme;

// Types -----------------------------------------------

// @deprecated use `palette.{color}`
interface GraphPalette {
  blue: string;
  lightblue: string;
  lightPink: string;
  pink: string;
  darkPink: string;
  lightTeal: string;
  teal: string;
  yellow: string;
  darkYellow: string;
  purple: string;
  lightGreen: string;
  green: string;
  red: string;
  lightOrange: string;
  orange: string;
}

type DefaultColor = Partial<Palette["primary"]>;

interface NewGraphPalette {
  orange: DefaultColor;
  blue: DefaultColor;
  red: DefaultColor;
  teal: DefaultColor;
  yellow: DefaultColor;
  green: DefaultColor;
  purple: DefaultColor;
  pink: DefaultColor;
}

interface UtfBackgroundColors {
  default: string;
  paper: string;
  selected: string;
  hover: string;
}
interface UtfBorderColors {
  primary: string;
  light: string;
  divider: DefaultColor;
}
interface UtfCommonColors {
  graph: GraphPalette;
  border: UtfBorderColors;
}

interface UtfPaletteColors {
  graph: GraphPalette;
  border: UtfBorderColors;
}

interface UtfPaletteOptions {
  common?: Partial<UtfCommonColors>;
  graph: Partial<UtfCommonColors>;
  border: Partial<UtfBorderColors>;
  background: Partial<UtfBackgroundColors>;
}

interface UtfPalette extends NewGraphPalette {
  common: UtfCommonColors;
  graph: GraphPalette;
  background: UtfBackgroundColors;
  border: UtfBorderColors;
}
interface UtfTypography {
  small: Typography["body1"];
}

interface UtfTheme {
  palette: UtfPalette;
  components?: UtfComponentOverrides;
}

interface UtfThemeOptions {
  palette?: UtfPaletteOptions;
  components?: UtfComponentOverrides;
}

interface OverrideComponentNameToClassKeys {}
type UtfComponentOverrides = {
  [TName in keyof OverrideComponentNameToClassKeys]?: {
    styleOverrides?: Partial<
      OverridesStyleRules<OverrideComponentNameToClassKeys[TName], TName, UtfTheme>
    >;
  };
};

declare module "@mui/material/styles" {
  export interface TypeBackground extends UtfBackgroundColors {}

  export interface CommonColors extends UtfCommonColors {}

  export interface PaletteColor extends UtfPaletteColors {}

  export interface PaletteOptions extends UtfPaletteOptions {}

  export interface Palette extends UtfPalette {}
  export interface Typography extends UtfTypography {}

  export interface Theme extends UtfTheme {}

  export interface ThemeOptions extends UtfThemeOptions {}
}

declare module "@mui/material/styles/createPalette" {
  interface Palette extends UtfPalette {}
  interface PaletteOptions extends Partial<UtfPalette> {}
}

declare module "@mui/private-theming/defaultTheme" {
  interface DefaultTheme extends UtfTheme {}
}
