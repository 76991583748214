/* eslint-disable no-case-declarations */
/*
initial author: Arun Tigeraniya<tigeraniya@gmail.com>
further read :
https://utilifeed.atlassian.net/wiki/spaces/ADWA/pages/898727937/Filter+Bar+2.0
https://utilifeed.atlassian.net/wiki/spaces/ADWA/pages/734593025/Filter+paramteres
*/
import { action, autorun, flow, makeObservable, observable } from "mobx";
import type { MRT_ColumnOrderState } from "material-react-table";

import { getValueFromBlock, SUBSTATION_BLOCK_TYPES as SBT } from "@conf/blocks";
import type { Filter, FilterState } from "@conf/filters.const";
import { FILTER_LIST, FilterDataOriginTypes, FilterTypes } from "@conf/filters.const";
import { logger as baseLogger } from "@core/logger";

import type { rootStore } from "./root_store";

const logger = baseLogger.getSubLogger({ name: "filterStore" });

/**
 * This function checks if all the options are selected in a filter.
 *
 * @param selected The set of selected options
 * @param options The array of all options
 * @returns True if all options are selected, false otherwise
 */
function areAllOptionsSelected(selected: Set<string> | undefined, options: string[]): boolean {
  if (!selected) return false;
  const uniqueOptions = new Set(options.flat());
  return selected.size === uniqueOptions.size && [...selected].every((v) => uniqueOptions.has(v));
}

type FilterJSON = Omit<Filter, "selected"> & {
  selected: string[];
} & Pick<FilterState, "isActive" | "is_active" | "min" | "max" | "include">;

export type FiltersJSON = {
  [filterParam: symbol]: FilterJSON;
};

class FiltersStore {
  filters: Filter[] = [];

  parent: typeof rootStore;

  isReady = false;

  filterColumnOrder: MRT_ColumnOrderState = [];

  constructor(parent: typeof rootStore) {
    makeObservable(this, {
      setExcludeMatches: true,
      updateSubstationIds: flow,
      filterColumnOrder: observable,
      parent: true,
      setExclude: action.bound,
      toJson: true,
      fromJson: action.bound,
      updateFavouritesFilter: flow.bound,
      setIncludeMissingData: action.bound,
      setSelectedOptions: action.bound,
      setHistogramDate: flow.bound,
      isReady: observable,
      currentNetworkId: true,
      fetchFilterData: flow.bound,
      filters: observable.deep,
      updateFilterState: action.bound,
      selectBounds: action.bound,
      releaseBounds: action.bound,
      updFilterActive: flow.bound,
      reset: flow.bound,
      loadAllFilters: flow.bound,
      resetFilters: action.bound,
    });
    this.parent = parent;

    autorun(() => {
      if (this.isReady) {
        // this use for reset the individual filter of selected filters between session
        if (sessionStorage.getItem("filterSession") !== "true") {
          this.resetFilters();
          sessionStorage.setItem("filterSession", "true");
        }
      }
    });
  }

  /**
   * This function is used to set the selected value of a categorical filter.
   * These are the values that are selected, not substation ids.
   *
   * @param {Filter} filter The filter to update
   * @param {string[]} options All available options for the filter.
   * @param {string[]} [selected] The values to mark as selected. If not provided, all options will be selected.
   */
  setSelectedOptions(filter: Filter, options: string[], selected?: string[]) {
    // Only allowed for categorical filters
    if (filter.type !== FilterTypes.CATEGORICAL) {
      logger.error(`Unable to select options for non-categorical filter ${filter.param}`);
      return;
    }
    const { state: filterState } = filter;
    if (areAllOptionsSelected(filterState.selected, options)) {
      return;
    }
    logger.debug("setSelectedOptions");
    // Update selected options
    filterState.selected = new Set(selected ?? options.flat());
    // Persist changes
    this.parent.preferences.upsertFilterStorage(this.filters);
  }

  /**
   * This function updates the year and month values of a Histogram filter.
   * @param spec The filter to update
   * @param year The year to set
   * @param month The month to set
   */
  setHistogramDate = flow(function* setHistogramDate(
    this: FiltersStore,
    spec: Filter,
    year: number,
    month: number | null
  ) {
    if (spec.type !== FilterTypes.HISTOGRAM) {
      logger.error(`Unable to set date for non-histogram filter ${spec.param}`);
      return;
    }
    const { state } = spec;
    const shouldUpdate = state.year !== year || state.month !== month;
    state.year = year;
    state.month = month;
    if (!shouldUpdate) return;
    // Then we need to fetch the data for the filter
    yield this.updFilterActive(spec.param, true);
  });

  /**
   * This function updates the favourites filter, if a substation is added or removed from favourites.
   */
  updateFavouritesFilter = flow(function* updateFavouritesFilter(this: FiltersStore) {
    const FILTER_FAV = "fav";
    const state = this.filters.find((f) => f.param === FILTER_FAV)?.state;
    if (!state) {
      logger.error("Unable to find favourites filter");
      return;
    }
    state.reset = !state.reset;
    yield this.updFilterActive(FILTER_FAV, true);
  });

  /**
   * This function updates the include value of a filter.
   * @param spec The filter to update
   * @param includeMissing The value to set
   */
  setIncludeMissingData(spec: Filter, includeMissing: boolean) {
    const { state } = spec;
    state.include = includeMissing;
    logger.debug("setIncludeMissingData %s", includeMissing);
  }

  /**
   * This function updates the excludeMatches value of a filter. The default value is false.
   * @param spec The filter to update
   * @param exclude The value to set for excludeMatches - default is false
   * @returns {void}
   * @usage
   * filters.setExcludeMatches(spec, true);
   * filters.setExcludeMatches(spec, false);
   * filters.setExcludeMatches(spec); // default is false
   */
  setExcludeMatches(spec: Filter, exclude = false): void {
    const { state } = spec;
    state.excludeMatches = exclude;
  }

  updateFilterState(param: string, key: keyof FilterState, val: unknown) {
    logger.debug("updateFilterState [%s] ", param, key, val);
    const filter = this.filters.find((f) => f.param === param) as Filter;

    const filterState = filter.state;
    // @ts-expect-error WTF is this error even? https://stackoverflow.com/questions/52423842/what-is-not-assignable-to-parameter-of-type-never-error-in-typescript
    filterState[key] = val;
  }

  setExclude(param: string, exclude: boolean) {
    const filter = this.filters.find((f) => f.param === param) as Filter;
    logger.debug("setExclude [%s] ", filter.param, exclude);
    const filterState = filter.state;
    filterState.excludeMatches = exclude;
    this.parent.preferences.upsertFilterStorage(this.filters);
  }

  selectBounds(spec: Filter, newValue: number, type: string): void {
    //! This should probably be a single value, [min, max]
    //! Because updating individual values causes the filter to be applied twice
    const filterState = spec.state;
    if (filterState) {
      if (type === "MIN") filterState.min = newValue;
      if (type === "MAX") filterState.max = newValue;
    }

    logger.debug(
      "%s changed - selectBounds[%s] (%s - %s)",
      spec.param,
      filterState.min,
      filterState.max,
      type
    );
    spec.state.selected = undefined;
  }

  get currentNetworkId() {
    return this.parent.networks.current_network?.uid;
  }

  fetchFilterData = flow(function* fetchFilterData(
    this: FiltersStore,
    filter: Filter
  ): Generator<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Map<string, any>;
    options: string[];
    missingSubs: number;
  }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapOfData = new Map<string, any>(); // Substation uid : value
    // Info block data
    const { newapi } = this.parent;
    const { block, param, state: filterState, dataOriginType } = filter;

    switch (dataOriginType) {
      case FilterDataOriginTypes.INFO_BLOCK:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const subBlock = (SBT as any)[block!];
        const blockName: string = subBlock.to_block_name({
          year: filterState.year,
          month: filterState.month,
        });

        if (!block) {
          logger.error(`Unable to find block ${block}`);
          return [];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const infoData: any = yield newapi.getInfoBlocksV4({
          resource_type: "network_substations",
          resource_id: this.currentNetworkId,
          block_names: [blockName],
        });
        // Map to key : value
        this.parent.networks.current_substations.forEach((_, substationId) => {
          const value = getValueFromBlock(infoData, blockName, substationId, param);
          mapOfData.set(substationId, value);
        });
        break;
      case FilterDataOriginTypes.NAME: // Name == User ID for the substation
        this.parent.networks.current_substations.forEach((name, substation) => {
          mapOfData.set(substation, name);
        });
        break;
      case FilterDataOriginTypes.CLUSTER:
        // For every substation, add the cluster name
        this.parent.networks.networkClusters.forEach((cluster) => {
          cluster.substations.forEach((substation) => {
            if (mapOfData.has(substation.uid)) {
              const values = mapOfData.get(substation.uid) || [];
              values.push(cluster.name);
              mapOfData.set(substation.uid, values);
            } else {
              mapOfData.set(substation.uid, [cluster.name]);
            }
          });
        });
        break;
      case FilterDataOriginTypes.CMS:
        const allSubs = this.parent.networks.current_substations;
        const favSubs = this.parent.preferences.fav_subs.get(this.currentNetworkId) ?? [];
        favSubs.forEach((sub: string) => {
          mapOfData.set(sub, "Favourites");
        });
        allSubs.forEach((_, sub) => {
          if (!mapOfData.has(sub)) {
            mapOfData.set(sub, "Others");
          }
        });
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        logger.error(`Unknown data origin type ${dataOriginType}`);
    }

    const missingSubsMap = new Map();
    // Now we remove any missing values from mapOfData
    mapOfData.forEach((value, key) => {
      //! This is the most important line in this whole file
      //! This determines whether a substation is included in the filter or not
      // The empty string is a hack, until the backend cleans up the data
      if (value === null || value === undefined || value === "") {
        mapOfData.delete(key);
        missingSubsMap.set(key, value);
      }
    });

    return {
      data: mapOfData,
      options: Array.from(mapOfData.values()),
      missingSubs: Array.from(missingSubsMap.keys()),
    };
  });

  releaseBounds(param: string) {
    logger.debug("releaseBounds [%s]", param);
    const filter = this.filters.find((f) => f.param === param) as Filter;
    const filterState = filter.state;

    filterState.min = filterState.minRange;
    filterState.max = filterState.maxRange;
  }

  updFilterActive = flow(function* (
    this: FiltersStore,
    param: string,
    state: boolean,
    initState?: FilterJSON
  ) {
    const filter = this.filters.find((f) => f.param === param) as Filter;
    if (!filter) {
      logger.error(`Unable to find filter ${param}`);
      return;
    }
    const filterState = filter.state;
    filterState.isActive = state;

    // TODO: This can be cleaned up, so that filters show, and a separate function
    // is used to fetch the data for the filter
    // If the filter is marked as active, we need to populate the data for it
    if (state) {
      filter.state.loading = true;
      logger.debug("Fetching filter data for block [%s]", filter.block, filter.param);
      const { data, options, missingSubs } = yield this.fetchFilterData(filter);
      filter.data = data;
      filter.substationsMissingData = missingSubs;
      switch (filter.type) {
        case FilterTypes.CATEGORICAL:
          // If the filter is categorical, we need to select all options by default
          filter.options = options.flat();
          this.setSelectedOptions(filter, options, initState?.selected);
          break;
        case FilterTypes.HISTOGRAM:
          // If the filter is a histogram, we need to set the min and max to the range
          filter.state.minRange = Math.floor(Math.min(...options));
          filter.state.maxRange = Math.ceil(Math.max(...options));
          filter.state.min = initState?.min ?? filter.state.minRange;
          filter.state.max = initState?.max ?? filter.state.maxRange;
          break;
        default:
          logger.error(`Unknown filter type ${filter.type as FilterTypes}`);
      }
      filter.state.loading = false;
      filter.state.include = initState?.include ?? true;
    }
    this.parent.preferences.upsertFilterStorage(this.filters);
  });

  loadAllFilters = flow(function* loadAllFilters(this: FiltersStore) {
    const { networks } = this.parent;
    if (!networks.ready) return;

    logger.debug("Loading all filters");
    FILTER_LIST.forEach((fspec: Filter) => {
      const state: Filter["state"] = {
        isActive: false,
        include: true,
        selected: fspec.state.selected ?? new Set(),
        scale: "linear",
        loading: false,
        excludeMatches: false,
      };

      const lastBlock = fspec.lb ?? "yearly";
      if (lastBlock === "yearly") {
        state.year = networks.lpYear.year;
        state.month = null;
      } else {
        state.year = networks.lpMonth.year;
        state.month = networks.lpMonth.month;
      }

      if (fspec.type === FilterTypes.HISTOGRAM) {
        state.min = null;
        state.max = null;
        state.minRange = null;
        state.maxRange = null;
      }

      const spec: Filter = {
        ...fspec,
        label: fspec.label,
        param: fspec.param,
        block: fspec.block,
        type: fspec.type,
        group: fspec.group,
        lb: lastBlock,
        is_recommended: fspec.is_recommended,
        state,
        dataOriginType: fspec.dataOriginType ?? FilterDataOriginTypes.INFO_BLOCK,
        data: new Map(),
        options: fspec.options ?? [],
        substationsMissingData: [],
      };
      this.filters.push(
        makeObservable(spec, {
          state: observable,
          data: observable,
        })
      );
    });

    // for every recommended filter, set it to active
    for (const filter of this.filters) {
      if (filter.is_recommended) {
        yield this.updFilterActive(filter.param, true);
      }
    }

    this.isReady = true;
  });

  resetFilters() {
    logger.debug("Resetting all filters");
    this.filters.forEach((spec: Filter) => {
      if (spec.state.isActive) {
        const { state } = spec;
        if (!state) {
          logger.error(`Unable to reset filter [${spec.param}] with no state`);
          return;
        }
        logger.debug(`Resetting [${spec.param}] filter`);
        // Reset state, to reset the search bar on categorical filters
        state.reset = !state.reset;

        // Reset selected options to the default values for that filter, or just clear
        // the set if there are no default values
        state.selected = new Set(spec.options ?? []);
        state.include = true;
        state.excludeMatches = false;
        state.min = state.minRange;
        state.max = state.maxRange;
      }
    });
  }

  /**
   * This function is used to reset the whole store, and clear all filters.
   * @returns {void}
   */
  reset = flow(async function* reset(this: FiltersStore) {
    if (!this.parent.networks.ready) return;
    logger.debug("Resetting filters...");
    // Read from the cache if exists
    if (this.parent.preferences.hasLocalFiltersCache) {
      this.filters = this.parent.preferences.getFilterStorage();
      this.isReady = true;
      return;
    }
    // Fetch the filters again
    this.isReady = false;
    this.filters = [];
    yield this.loadAllFilters();
  });

  /**
   * This function is used to get the current state of the filters.
   *
   * @param {boolean} onlyActive Whether to only return the active filters
   * @returns {object} JSON representation of the filters
   * @usage
   * const filters = filtersStore.toJson();
   * const activeFilters = filtersStore.toJson(true);
   */
  toJson(onlyActive = true): {
    network: string;
    filters: FiltersJSON;
  } {
    const output: { [key: string]: FilterState } = {};
    this.filters
      .filter((filter) => !onlyActive || filter.state.isActive)
      .forEach((filter: Filter) => {
        output[filter.param] = {
          isActive: filter.state.isActive ?? false,
          // @ts-expect-error intentional conversion
          selected: Array.from(filter.state?.selected ?? []),
          min: filter.state?.min ?? null,
          max: filter.state?.max ?? null,
          include: filter.state?.include ?? true,
          excludeMatches: filter.state?.excludeMatches ?? false,
        };
      });
    const network = String(this.parent.networks.current_network?.name);
    return { network, filters: output };
  }

  /**
   * This function is used to load the state of the filters from a JSON object.
   *
   * @param {object} jsn JSON representation of the filters
   * @param {object} jsn.filters The filters to load
   * @returns {void}
   * @usage
   * filtersStore.fromJson(filters);
   */
  fromJson(jsn: { filters: FiltersJSON }): void {
    if ("filters" in jsn) {
      logger.error("Unable to load filters from JSON");
      return;
    }
    const { filters: filtersFromFile } = jsn;
    // Disable all filters
    this.filters.forEach((oldFilter) => {
      oldFilter.state.isActive = false;
    });
    // For each filter in the file, update the state of the filter
    // making sure to use updFilterActive, so that the data is fetched
    // for the filter
    Object.keys(filtersFromFile).forEach((param) => {
      const filter = this.filters.find((f) => f.param === param) as Filter;
      if (!filter) {
        logger.error(`Unable to find filter ${param}`);
        return;
      }
      const filterStateFromFile = filtersFromFile[param] as FilterJSON;
      const shouldActivate = filterStateFromFile.isActive ?? filterStateFromFile.is_active ?? false;
      if (shouldActivate) {
        void this.updFilterActive(param, true, filterStateFromFile);
      }
    });
  }

  /**
   * This function is used by external components and stores to update the substation ID filter.
   * Either it just adds to the selected list, or it replaces the selected list.
   * If the filter is not active, it will be activated.
   *
   * @param {string: string[]} substationIds The substation IDs to add to the filter
   * @param {boolean} replace Whether to replace the selected list or not
   * @returns {void}
   * @usage
   * filtersStore.updateSubstationIds(["sub1", "sub2"]);
   * filtersStore.updateSubstationIds(["sub1", "sub2"], true);
   * filtersStore.updateSubstationIds(["sub1", "sub2"], false);
   */
  updateSubstationIds = flow(function* updateSubstationIds(
    this: FiltersStore,
    substationIds: string[],
    replace = false,
    reset = false
  ) {
    const param = "substation_uid"; // If we want to send to different filters, we can add a param to the function

    // if reset is true, we should reset the filter
    if (reset) {
      yield this.updFilterActive(param, false);
      return;
    }

    let shouldReplace = replace;
    const substationFilter = this.filters.find((f) => f.param === param) as Filter;
    if (!substationFilter) {
      logger.error("Unable to find substation filter");
      return;
    }
    if (!substationFilter.state.isActive) {
      yield this.updFilterActive(param, true);
      // By default, everything is included, so if it was off, we should replace the selected list
      shouldReplace = true;
    }
    if (shouldReplace) {
      substationFilter.state.selected = new Set(substationIds);
    } else {
      this.setSelectedOptions(substationFilter, substationIds);
    }
  });
}

export { FiltersStore };
