import { useRef, useState } from "react";
import { observer } from "mobx-react";
import { Autocomplete, Box, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

import { SubHeaderSection } from "@components/ui/SubHeader";
import ClusterIcon from "@icons/Cluster";
import SubstationIcon from "@icons/Substation";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

type SearchBoxAddress = {
  id: string;
  name: string;
  customer?: string;
  street?: string;
};

type SearchBoxProps = {
  searchData: SearchBoxAddress[];
  selectedValue: SearchBoxAddress;
  onOptionSelected: (value: SearchBoxAddress) => void;
  isSubstation: boolean;
  isLoading: boolean;
};

const infoLoader = <Skeleton animation="wave" sx={{ bgcolor: "white" }} variant="rectangular" />;

export const SearchBox = observer(
  ({ searchData, selectedValue, isSubstation, onOptionSelected, isLoading }: SearchBoxProps) => {
    const [searchEnabled, setSearchEnabled] = useState(false);

    // Reference to the info box for 300 calculations
    const infoboxRef = useRef<HTMLDivElement>();

    return (
      <>
        {isSubstation ? <SubstationIcon /> : <ClusterIcon />}
        {isLoading && infoLoader}
        {!isLoading && (
          <>
            <Box
              display="flex"
              onClick={() => {
                setSearchEnabled(true);
              }}
            >
              {!searchEnabled && (
                <Box
                  ref={infoboxRef}
                  minWidth="200px"
                  display="flex"
                  alignItems="center"
                  data-testid="extend-header-info"
                >
                  <SubHeaderSection sx={{ minWidth: isSubstation ? "100px" : "200px" }}>
                    <Typography variant="h5" data-testid="extend-panel-title">
                      {selectedValue.name}
                    </Typography>
                  </SubHeaderSection>
                  {isSubstation && (
                    <>
                      <SubHeaderSection sx={{ minWidth: "100px" }}>
                        <Typography variant="h6">{selectedValue.customer}</Typography>
                      </SubHeaderSection>
                      <SubHeaderSection sx={{ minWidth: "100px" }}>
                        <Typography variant="h6">{selectedValue.street}</Typography>
                      </SubHeaderSection>
                    </>
                  )}
                </Box>
              )}
            </Box>

            {/* Search */}
            <Tooltip arrow placement="top" title="Click to search">
              <Autocomplete
                blurOnSelect
                disableClearable
                disablePortal
                clearOnBlur
                popupIcon={<FontAwesomeSvgIcon icon={faMagnifyingGlass} />}
                open={searchEnabled}
                value={selectedValue}
                loading={isLoading}
                options={searchData}
                getOptionLabel={(option) => option.name || "HELP"}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} />}
                // Render options
                renderOption={(props, { name, street, customer: customerName }) => (
                  <Box
                    component="li"
                    sx={{
                      "&&": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      },
                    }}
                    {...props}
                  >
                    <Typography
                      sx={{
                        typography: "h3",
                        fontSize: 14,
                        lineHeight: 1,
                        color: "grey.600",
                      }}
                    >
                      {customerName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        lineHeight: 1.6,
                      }}
                    >
                      {street}
                    </Typography>
                    <Typography
                      sx={{
                        typography: "h6",
                        color: "grey.600",
                        pt: 1.5,
                        fontSize: 12,
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                )}
                onChange={(_, value) => {
                  onOptionSelected(value);
                }}
                onOpen={() => {
                  setSearchEnabled(true);
                }}
                onClose={() => {
                  setSearchEnabled(false);
                }}
                sx={{
                  minWidth: `340px`,
                  marginLeft: searchEnabled ? "0" : `-300px`,
                  "& .MuiInputBase-root": {
                    typography: "h6",
                    color: "white",
                    input: {
                      // Move the text out of the screen
                      // This is a hack to make invisible input focused
                      textIndent: !searchEnabled ? "-9999px" : "0",
                    },
                    fieldset: {
                      border: "none",
                    },
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "white",
                    transform: "none !important",
                    transition: "color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  },
                  "&.Mui-expanded": {
                    "& .MuiAutocomplete-popupIndicator": {
                      color: "secondary.main",
                    },
                  },
                }}
              />
            </Tooltip>
          </>
        )}
      </>
    );
  }
);
