export const R12 = "rolling 12 months";

export type TimePeriod = "yearly" | "monthly" | "weekly";

export const TIME_PERIOD: Record<TimePeriod, TimePeriod> = {
  yearly: "yearly",
  monthly: "monthly",
  weekly: "weekly",
};

export const TIME_PERIOD_LABELS = {
  [TIME_PERIOD.yearly]: "text_yearly_values",
  [TIME_PERIOD.monthly]: "text_monthly_values",
  [TIME_PERIOD.weekly]: "text_weekly_values",
};

export const YEAR_MIN_VALUE = 2014;

export const PARAMETER = {
  return_temperature: "rt",
  delta_temperature: "dt",
};

export const PARAMETER_LABELS = {
  [PARAMETER.return_temperature]: "text_return_temperature",
  [PARAMETER.delta_temperature]: "text_delta_temperature",
};

export const PARAMETER_LONG_LABELS = {
  [PARAMETER.return_temperature]: "text_return_temperature",
  [PARAMETER.delta_temperature]: "text_delta_temperature",
};

export type SectionKeys = "rt" | "rtsp" | "rtab";
export type SectionValues = "analysis" | "production_savings" | "table";

export const SECTION: Record<SectionKeys, SectionValues> = {
  rt: "analysis",
  rtsp: "production_savings",
  rtab: "table",
};

export const SOURCE = {
  normal: "epc_",
  measured: "",
};

export const SOURCE_OPTIONS = {
  [SOURCE.normal]: "text_normal_year",
  [SOURCE.measured]: "text_measured",
};

export const SOURCE_LABELS = {
  [SOURCE.normal]: "text_normal_year_values",
  [SOURCE.measured]: "text_measured_values",
};

export const WEIGHT = {
  flow_weighted: "flowweighted_avg",
  non_weighted: "unweighted_avg",
  power_weighted: "powerweighted_avg",
};

export const WEIGHT_LABELS = {
  [WEIGHT.flow_weighted]: "text_flow_weighted",
  [WEIGHT.non_weighted]: "text_non_weighted",
  [WEIGHT.power_weighted]: "text_power_weighted",
};

export const WEIGHT_DESCRIPTIONS = {
  [WEIGHT.flow_weighted]: "weighted by the flow for that time step",
  [WEIGHT.non_weighted]: "weighted equally (no weighing after e.g. flow)",
  [WEIGHT.power_weighted]: "weighted by the energy used for that time step",
};

export const RT_YAXIS = {
  substations: "count",
  volume: "vol",
  energy: "heat",
  power: "power",
  avg_flow: "flow",
};

export const RT_YAXIS_FOLLOW = {
  [WEIGHT.flow_weighted]: RT_YAXIS.volume,
  [WEIGHT.power_weighted]: RT_YAXIS.energy,
  [WEIGHT.non_weighted]: RT_YAXIS.substations,
};

export const RT_YAXIS_OPTIONS = new Map([
  [RT_YAXIS.substations, "text_#_substations"],
  [RT_YAXIS.avg_flow, "text_average_flow"],
  [RT_YAXIS.energy, "text_energy"],
  [RT_YAXIS.power, "text_power"],
  [RT_YAXIS.volume, "text_volume"],
]);

export const RT_YAXIS_UNITS = new Map([
  [RT_YAXIS.substations, ""],
  [RT_YAXIS.volume, "m³"],
  [RT_YAXIS.energy, "kWh"],
  [RT_YAXIS.power, "kW"],
  [RT_YAXIS.avg_flow, "m³/h"],
]);

export const SCATTER_XAXIS = {
  avg_flow: "flow",
  energy: "heat",
  power: "power",
  volume: "vol",
};

export const SCATTER_XAXIS_LABELS = {
  [SCATTER_XAXIS.avg_flow]: "text_average_flow",
  [SCATTER_XAXIS.energy]: "text_energy",
  [SCATTER_XAXIS.power]: "text_power",
  [SCATTER_XAXIS.volume]: "text_volume",
};

export const POTENTIAL_TYPE = {
  cost_saving: "cost_saving",
  flow_reduction: "flow_reduction",
};

export const POTENTIAL_TYPE_OPTIONS = new Map([
  [POTENTIAL_TYPE.cost_saving, "text_cost_saving"],
  [POTENTIAL_TYPE.flow_reduction, "text_flow_reduction"],
]);
export const POTENTIAL_PANEL_TITLE = new Map([
  [POTENTIAL_TYPE.cost_saving, "text_production_cost_savings_Optimization"],
  [POTENTIAL_TYPE.flow_reduction, "text_production_flow_savings_Optimization"],
]);

export const POTENTIAL_HISTOGRAM_LABEL = new Map([
  [POTENTIAL_TYPE.cost_saving, "text_cost_savings_histogram"],
  [POTENTIAL_TYPE.flow_reduction, "text_flow_reduction_histogram"],
]);

export const POTENTIAL_LINECHART_LABEL = new Map([
  [POTENTIAL_TYPE.cost_saving, "text_accumulated_cost_saving_potential"],
  [POTENTIAL_TYPE.flow_reduction, "text_accumulated_flow_saving_potential"],
]);

export const DEFAULT_TARGET_TEMP = "35";
export const DEFAULT_TARGET_ENERGY = "1,24";
export const TEXT_INSTALL_ADDRESS_STREET = "install_address.street";
export const TEXT_CUSTOMER_NAME = "customer.name";
